import React from 'react'
import {Container} from 'react-bootstrap'
import {section, styleGray, stylePurple} from './Section.module.scss'

interface Props extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode
  background?: 'gray' | 'purple'
  // TODO drop support unused bg attribute
  bg?: string
  relative?: boolean
}

const Section: React.FC<Props> = (props) => {
  const {children, className, background, relative = false} = props

  const bgStyle = getBackgroundStyle()

  function getBackgroundStyle() {
    if (background === 'gray') return styleGray
    if (background === 'purple') return stylePurple
    return ''
  }

  return (
    <section {...props} className={`${section} ${className || ''} ${bgStyle}`}>
      <Container className={relative ? 'position-relative' : ''}>
        {children}
      </Container>
    </section>
  )
}

export default Section
