import React from 'react'
import {button} from './ShareButton.module.scss'
import FbIcon from './img/fb-icon.inline.svg'
import ShareIcon from './img/share-icon.inline.svg'

interface Props {
  url: string
  text: string
  className?: string
}

const ShareButton = ({className = '', url, text}: Props) => {
  const handlerClickShare = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      'facebook-share-dialog',
      'width=600,height=600',
    )
  }
  return (
    <button className={`${button} ${className}`} onClick={handlerClickShare}>
      <ShareIcon />
      <span>{text}</span>
      <FbIcon />
    </button>
  )
}

export default ShareButton
