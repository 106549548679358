import React, {useCallback, useEffect, useState} from 'react'
import {Navbar, Container, Nav} from 'react-bootstrap'
import './Header.scss'

import Logo from './logo.inline.svg'
import LogoDesktop from './logo-desktop.inline.svg'

const menuItems = [
  {
    id: 1,
    href: '#basics',
    text: 'What is chickenpox?',
  },
  {
    id: 2,
    href: '#managing-spots',
    text: 'Management',
  },
  {
    id: 3,
    href: '#possible-complications',
    text: 'Complications',
  },
  {
    id: 4,
    href: '#faq',
    text: 'FAQs',
  },
]

type Direction = null | 'up' | 'down'

const Header: React.FC = () => {
  const [prevScroll, setPrevScroll] = useState(0)
  const [prevDirection, setPrevDirection] = useState<Direction>(null)
  const desktopWidth = 976
  const navHeight = 107

  const toggleNavbar = (dir: Direction, scroll: number) => {
    if (dir === 'down' && scroll > navHeight) setPrevDirection(dir)
    if (dir === 'up') setPrevDirection(dir)
  }

  const checkScroll = useCallback(() => {
    if (desktopWidth <= window.innerWidth) return

    const scroll = window.scrollY
    const direction = scroll > prevScroll ? 'down' : 'up'

    if (direction !== prevDirection) toggleNavbar(direction, scroll)

    setPrevScroll(scroll)
  }, [prevScroll, prevDirection])

  useEffect(() => {
    window.onscroll = checkScroll
  }, [checkScroll])

  return (
    <>
      <Container>
        <p className="header-info-text">
          This website is produced and funded by MSD. It is intended to provide
          general educational information and does not take the place of
          professional advice.
        </p>
      </Container>
      <Navbar
        as="header"
        bg="primary"
        expand="lg"
        sticky="top"
        className={`${prevDirection === 'down' ? 'navbar-hide' : ''}`}
      >
        <Container fluid="xxl" as="nav" className="justify-content-between">
          <Navbar.Brand>
            <a href="#section-1" className="text-decoration-none">
              <Logo className="d-inline-block d-lg-none align-top" />
              <LogoDesktop className="d-none d-lg-inline-block align-top" />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="main-nav" className="my-2" />
          <Navbar.Collapse id="main-nav">
            <Nav className="justify-content-end w-100">
              {menuItems.map(({id, href, text}) => (
                <Nav.Link key={id} href={href}>
                  {text}
                </Nav.Link>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Header
